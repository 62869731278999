<template>
  <div class="px-5" id="page-sendout-email">
    <h5 class="mb-6 ml-2">{{$t('PAGES.SENDOUT.SMS_SENDOUT')}}</h5>

        <b-card class="mb-8">
          <template #header>
              <p class="h5 mb-1">
                <span :class="`svg-icon svg-icon-md mr-2 text-${(sender_ok === null || sender_ok) ? 'success' : 'danger'}`">
                  <inline-svg
                    :src="(sender_ok === null || sender_ok) ? '/assets/svg/check-circle.svg' : '/assets/svg/x-circle.svg'"
                  ></inline-svg>
                </span>
              {{$t('PAGES.SENDOUT.SENDER')}}
              </p>
          </template>

          <b-row>
            <b-col>
              <b-form-group
                id="input-group-1"
                :label="$t('PAGES.SENDOUT.REMAINING_SMS_BUDGET')"
                label-for="input-1"
                class="flex-fill mr-4"
              >
                <b-form-input
                  id="input-remaining_sms_budget"
                  v-model="remaining_sms_budget"
                  disabled
                  type="text"
                  :placeholder="$t('PAGES.SENDOUT.REMAINING_SMS_BUDGET')"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>


          <b-row>
              <b-col>
                <b-row>
                    <b-col align="start">
                    <label for="from_id-name">{{$t('PAGES.SENDOUT.FROM_ID')}}</label>
                    </b-col>
                    <b-col align="end">
                    <label for="from_id-name"
                        >{{
                        11 - (local_item.overrides.from_id ? local_item.overrides.from_id.length : 0)
                        }}
                        {{$t('PAGES.SENDOUT.CHARACTERS_REMAINING')}}</label
                    >
                    </b-col>
                </b-row>
                <b-form-group>
                    <b-form-input
                    id="from_id-name"
                    v-model="local_item.overrides.from_id"
                    type="text"
                    :state="sender_ok"
                    />
                    <b-form-text id="input-live-help"
                    >{{$t('PAGES.SENDOUT.SENDER_ID_INFO')}}</b-form-text
                    >
                </b-form-group>
              </b-col>
          </b-row>

        </b-card>

        <!--Begin:: template selection-->
        <b-card class="mb-8">
            <template #header>
              <p class="h5 mb-1">
                <span :class="`svg-icon svg-icon-md mr-2 text-${local_item.template_id ? 'success' : 'danger'}`">
                  <inline-svg
                    :src="local_item.template_id ? '/assets/svg/check-circle.svg' : '/assets/svg/x-circle.svg'"
                  ></inline-svg>
                </span>
                {{$t('PAGES.SENDOUT.SMS_SELECT_TEMPLATE')}}
              </p>
            </template>

            <b-form-group
              id="template_id-group"
              :label="$t('PAGES.SENDOUT.SMS_TEMPLATE')"
              label-for="template_id"
            >
              <b-row>
                <b-col cols="8">
                  <b-form-select
                    id="template_id"
                    v-model="local_item.template_id"
                    :options="templateOptions"
                  ></b-form-select>
                </b-col>
                <b-col cols="4">
                  <b-row>
                    <b-col class="mb-4">
                      <a
                        href="#"
                        style="min-width: 180px;"
                        class="btn btn-primary font-weight-bolder font-size-sm ml-4"
                        @click="select_template($event)"
                        :disabled="isTmpReview"
                        ><i class="menu-icon flaticon-eye" style="font-size: 1.0em;"></i>{{$t('PAGES.SENDOUT.REVIEW')}}</a>

                    </b-col>
                    <b-col>
                        <a
                        href="#"
                        style="min-width: 180px;"
                        class="btn btn-primary font-weight-bolder font-size-sm ml-4"
                        @click="create_template"
                        ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{$t('PAGES.SENDOUT.CREATE_NEW_TEMPLATE')}}</a>

                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-alert class="mt-5" v-show="!template_ok" show variant="danger"
                >{{$t('PAGES.SENDOUT.TEMPLATE_MUST_BE_SELECTED')}}</b-alert
              >
            </b-form-group>
          </b-card>

          <b-alert :show="!all_ok" :variant="'warning'">{{$t('PAGES.SENDOUT.CORRECT_INFO')}}</b-alert>
          <b-alert :show="all_ok" :variant="'success'">{{$t('PAGES.SENDOUT.ALL_CORRECT')}}</b-alert>

          <b-modal size="lg" scrollable v-model="template_review" hide-footer>
            <div v-html="template_html"></div>
          </b-modal>
          <!--End:: template selection-->

  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'SendoutSMS',
  props: ['item'],
  emits: ['update:item'],
  components: {

  },
  mixins: [ toasts ],
  computed: {
    sender_ok() {
      if (this.local_item.overrides?.from_id === null || this.local_item.overrides?.from_id === undefined || this.local_item.overrides?.from_id?.length === 0) {
        return null;
      }

      if (this.local_item.overrides.from_id.length > 11) {

        return false;
      }

      const re = /[^a-z\d]/i;

      const test = !re.test(String(this.local_item.overrides?.from_id).toLowerCase());

      return test;
    },
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'customer']),

    all_ok() {
      const ok = this.sender_ok;

      return (ok === null || ok) && this.template_ok;
    },
  },
  watch: {
    local_item: {
      deep: true,
      handler(newVal) {
        this.$emit('update:item', newVal);
      }
    },
    'local_item.template_id' (newValue, oldValue) {
      if (newValue && newValue !== '') {
        this.template_ok = true;
      }
      else {
        this.template_ok = false;
      }
    }

  },
  mounted() {
    this.load_templates(true);
    this.local_item = { ...this.item };
    this.fetch_remaining_budget();
  },
  data() {
    return {
      remaining_sms_budget: 0,
      editing: false,
      creating: false,
      templates: [],
      templateOptions: [],
      isTmpReview: false,
      template_html: '',
      template_review: false,
      template_ok: false,
      local_item: {
        prio: 'imp',
        sendout_id: '',
        company_id: '',
        status: '', // todo, list status types
        created_at: '2020-01-01',
        template_id: '',
        query_id: '',
        type: 'email', // todo, list type types
        name: '',
        member_ids: [],
        overrides: {
          subject: '',
          preview: '',
          from_name: '',
          reply_to: null,
          from_email: null,
          from_id: null
        }
      },
    };
  },
  methods: {
    /**
     * To create new template.
     * template_id = -1
     * editing = 0
     */
    create_template() {
      this.$router.push(`/ml-templates/${this.local_item.sendout_id}/0/-1`);
    },

    async fetch_remaining_budget() {
      try {
        const res = await axios.get(`/billing/active/${this.customer.root_company_id}`);

        if (res.status === 200) {
          this.remaining_sms_budget = res.data.sms_budget - res.data.sms_budget_spent;
        }
      }
      catch (err) {
        console.error('fetch_remaining_budget error', err);
        this.remaining_sms_budget = 0;
      }
      
    },


    async select_template() {
      if (this.local_item.template_id && this.local_item.template_id !== '') {
        this.isTmpReview = true;

        try {
          const res = await axios.get(`/template/${this.local_item.template_id}`);

          if (res.status === 200) {
            this.template_html = res.data.template;
            this.template_review = true;
          }
        } catch (err) {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SENDOUT.UNABLE_SELECT_TEMPLATE'));
        }
      }
      else {
        this.template_html = '';
      }
    },
    async load_templates(is_sms) {
      try {
        const res = await axios.get(`/template?company_id=${this.currentCompanyId}`);

        if (res.status === 200) {
          this.templates = res.data;
        }

        this.templates = this.templates.filter(item => item.is_sms == is_sms);

        this.templateOptions = [];

        for (const t of this.templates) {
          this.templateOptions.push({
            value: t.template_id,
            text: t.name
          });
        }
      } catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SENDOUT.UNABLE_LIST'));
      }
    },
  }
};
</script>
